<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>All Invoices</v-tab>
            <v-tab>Approved</v-tab>
            <v-tab>Pending</v-tab>
            <v-tab>Cancelled</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="all_invoices"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="approved_invoices"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="pending_invoices"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="cancelled_invoices"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    color: "",
    snackbar: false,
    search: "",

    all_invoices: [],
    approved_invoices : [],
    pending_invoices : [],
    cancelled_invoices : [],
    headers: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Admin Status", value: "admin_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "Ref.No", value: "reference_id", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    loading: true,
  }),

  methods: {
    initialize() {
      // Get all invoices of this admin
      axios
        .post(`Cart/admin_seller_invoices/`, { type: "All" })
        .then((response) => {
          this.all_invoices = response.data.data;
          this.loading = false;
        });

      // Get all approved invoices of this admin
      axios
        .post(`Cart/admin_seller_invoices/`, {
          type: "Approved",
        })
        .then((response) => {
          this.approved_invoices = response.data.data;
        });

      // Get all pending invoices of this admin
      axios
        .post(`Cart/admin_seller_invoices/`, { type: "Pending" })
        .then((response) => {
          this.pending_invoices = response.data.data;
        });

      // Get all cancelled invoices of this admin
      axios
        .post(`Cart/admin_seller_invoices/`, {
          type: "Cancelled",
        })
        .then((response) => {
          this.cancelled_invoices = response.data.data;
        });
    },

    // Details of an invoice
    view(item) {
      this.$router.push({
        path: "/supportdashboard/pages/approveinvoice",
        query: { id: item.invoice_id },
      });
    },
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.initialize();
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

